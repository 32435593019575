<template>


<!-- OLD PAGE WELCOME B2B -->

<!-- <div class="hero">
  <div class="content">

    <div class="info">

      <img class="logo_premium" src="/src/brand-manager/images/business_black.svg">

      <div class="title">{php} echo Lng::get('brand_manager_landing_title'){/php}</div>
      <div class="text">{php} echo Lng::get('brand_manager_landing_subtitle'){/php}</div>

      {if $is_b2b}
        <a href="/brand-manager/subscription" class="ui button yellow big btn_get_trial">{php} echo Lng::get('brand_manager_landing_button'){/php}</a>

        <div class="sub">
          {php} echo Lng::get('brand_manager_landing_button_sub1'){/php}
        </div>


      {else}      
        <a href="/contact" class="ui button yellow big btn_get_trial">{php} echo Lng::get('brand_manager_landing_contact_us'){/php}</a>

        <div class="sub">
          {php} echo Lng::get('brand_manager_landing_contact_us_to_get'){/php}
        </div>

       {/if}


    </div>

    <img class="back_img" src="/src/brand-manager/images/b2b_back.jpg">

  </div>
</div>

 




    <div class="table_price">

      <div class="head">
        <div class="td">{php} echo Lng::get('brand_manager_landing_table_col1'){/php}</div>
        <div class="td">{php} echo Lng::get('brand_manager_landing_table_col2'){/php}</div>
      </div>
      <div class="body">
        <div class="tr">
          <div class="th">{php} echo Lng::get('brand_manager_landing_table_row1'){/php}</div>
          <div class="td"><i class="icon check circle green"></i></div>
          <div class="td"><i class="icon check circle green"></i></div>
        </div>
        <div class="tr">
          <div class="th">{php} echo Lng::get('brand_manager_landing_table_row2'){/php}</div>
          <div class="td"><i class="icon close circle red"></i></div>
          <div class="td"><i class="icon check circle green"></i></div>
        </div>
        <div class="tr">
          <div class="th">{php} echo Lng::get('brand_manager_landing_table_row3'){/php}</div>
          <div class="td"><i class="icon close circle red"></i></div>
          <div class="td"><i class="icon check circle green"></i></div>
        </div>
        <div class="tr">
          <div class="th">{php} echo Lng::get('brand_manager_landing_table_row4'){/php}</div>
          <div class="td"><i class="icon close circle red"></i></div>
          <div class="td"><i class="icon check circle green"></i></div>
        </div>
      </div>

    </div>
 


<div class="bl_box">
  <div class="content">

    <div class="drs">
      <img src="/src/brand-manager/images/ic_1.jpg">
    </div>

    <div class="info">
      <h2>{php} echo Lng::get('brand_manager_landing_slide1_title'){/php}</h2>
      <div class="sub">{php} echo Lng::get('brand_manager_landing_slide1_subtitle'){/php}</div>
    </div>

  </div>
</div>
 


<div class="bl_box reverse">
  <div class="content">

    <div class="drs">
      <img src="/src/brand-manager/images/ic_2.jpg">
      <div class="feats">
        <div class="feat feat1">{php} echo Lng::get('brand_manager_landing_slide2_feat1'){/php}</div>
        <div class="feat feat2">{php} echo Lng::get('brand_manager_landing_slide2_feat2'){/php}</div>
        <div class="feat feat3">{php} echo Lng::get('brand_manager_landing_slide2_feat3'){/php}</div>
        <div class="feat feat4">{php} echo Lng::get('brand_manager_landing_slide2_feat4'){/php}</div>
      </div>
    </div>

    <div class="info">
      <h2>{php} echo Lng::get('brand_manager_landing_slide2_title'){/php}</h2>
      <div class="sub">{php} echo Lng::get('brand_manager_landing_slide2_subtitle'){/php}</div>
    </div>

  </div>
</div>
 


<div class="bl_box">
  <div class="content">

    <div class="drs">
      <img src="/src/brand-manager/images/ic_3.jpg">
    </div>

    <div class="info">
      <h2>{php} echo Lng::get('brand_manager_landing_slide3_title'){/php}</h2>
      <div class="sub">{php} echo Lng::get('brand_manager_landing_slide3_subtitle'){/php}</div>
    </div>

  </div>
</div>



 


<div class="bl_box reverse">
  <div class="content">

    <div class="drs">
      <img src="/src/brand-manager/images/ic_4.jpg">
    </div>

    <div class="info">
      <h2>{php} echo Lng::get('brand_manager_landing_slide4_title'){/php}</h2>
      <div class="sub">{php} echo Lng::get('brand_manager_landing_slide4_subtitle'){/php}</div>
    </div>

  </div>
</div>

 


<div class="bl_box_link">
  <div class="content">

      {if $is_b2b}
        <a href="/brand-manager/subscription" class="ui button yellow big btn_get_trial">{php} echo Lng::get('brand_manager_landing_button'){/php}</a>
      {else}   
        <a href="/contact" class="ui button yellow big btn_get_trial">{php} echo Lng::get('brand_manager_landing_contact_us'){/php}</a>
      {/if}

  </div>
</div> -->

</template>

<script setup>

const { $api, $head } = useNuxtApp();

const loadData = async () => {
  return await $api.getStaticPage('brands-manager/welcome');  
}


const { pending: is_loading,  data: data } = await useLazyAsyncData('data', async () => await loadData())



useHead(useNuxtApp().$head.getStaticPage());
 

</script>

<style scoped>

.text-style-info{
  text-indent: 2rem;
}

.html_into :v-deep(.de_im){
  height: 14px;
  vertical-align: middle;
}
 
</style>